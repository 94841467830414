body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'avenir next', 'avenir',
		'helvetica neue', 'helvetica', 'ubuntu', 'roboto', 'noto', 'segoe ui',
		'arial', sans-serif;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: white;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
	background: white;
}
