body {
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'avenir next', 'avenir',
		'helvetica neue', 'helvetica', 'ubuntu', 'roboto', 'noto', 'segoe ui',
		'arial', sans-serif;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: white;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
	background: white;
}


/* Global Styles */
body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  color: #424241;
  font-weight: 450;
  overflow-y: scroll;
}

pre {
  background-color: #f0f0f0;
  word-break: normal !important;
  word-wrap: normal !important;
  white-space: pre !important;
  overflow: auto;
}

blockquote {
  margin:16px 0;font-size:inherit;padding:0 15px;color:#5c5c5c;background-color:#f0f0f0;border-left:4px solid #d6d6d6
}

code {
	font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
	font-size: .85em !important;
	color: #000;
	background-color: #f0f0f0;
	border-radius: 3px;
	padding: .2em 0;
}
code::before,
code::after {
	letter-spacing: -0.2em;
	content: "\A0"
}


a {
  font-weight: bold;
  color: #385e94;
}

a:hover {
  color: #253a57;
  border-bottom: 1px solid #253a57;
}

.btn {
  background-color: #385e94 !important;
  color: white !important;
  font-weight: bolder !important;
}

.btn:hover {
  background-color: #253a57 !important;
}

ul {
  padding-left: 3%;
}

#sidebar-col > ul{
  list-style: none;
}

.book-img{
  width: 25%;
}

li {
  margin-top: 2%;
}
#navlinks > li > a {
  font-weight: 600 !important;
}

#categories-header {
  font-weight: 600;
}
.categories > li {
  margin-top: 2%;
  opacity: 0.75;
}

.about-list {
  list-style: disc;
}

#about-section > div {
  padding-top: 1%;
  padding-bottom: 1%;
}

#project-section > div {
  padding-top: 1%;
  padding-bottom: 1%;
}

#contact-section > div {
  padding-top: 1%;
  padding-bottom: 1%;
}

#social-list {
  padding-left: 1% !important;
}

#social-list > li {
  margin-top: 0.5%;
}

#book-list {
  padding-left: 0.5% !important;
}

.content-date {
  font-weight: 500;
  color: #999;
}

.content-content {
  font-weight: 400;
  line-height: 1.6em;
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: large;
}

.content-content p{
  font-size: large;

}

h4{
  font-size: large;
}

.post-header {
  font-size: 1.8em;
}

.twitter-btn {
  margin-top: 5%;
  text-align: center;
  margin-bottom: 5%;
}

.tag-section {
  margin-top: 1%;
}

.footer-section {
  margin-top: 1%;
  margin-bottom: 2%;
}

.ui.pagination.menu > a {
  border: none;
}

h2:first-child{
  margin-top: calc(2rem - 0.142857em);
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-left: 0px;
}

h2:last-child{
  margin-top: calc(2rem - 0.142857em);
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-left: 0px;  
}

h3:first-child{
  margin-top: calc(2rem - 0.142857em);
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-left: 0px;
}

h3:last-child{
  margin-top: calc(2rem - 0.142857em);
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-left: 0px; 
}

h4:first-child{
  margin-top: calc(2rem - 0.142857em);
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-left: 0px;
}

h4:last-child{
  margin-top: calc(2rem - 0.142857em);
  margin-right: 0px;
  margin-bottom: 1rem;
  margin-left: 0px;  
}


.article-img-large{
  width: 55vw;
  display: block;
  margin: 0 auto;
}

.article-img-small{
  width: 35vw;
  display: block;
  margin: 0 auto;
}

p, li{
  font-size: medium;
}

/* Mobile Breakpoints */
@media only screen and (max-width: 599px) {
  body {
    padding-top: 5%;
    padding-left: 1%;
    padding-right: 1%;
  }

  #main-section {
    background-color: white;
  }

  #sidebar-col {
    min-width: initial;
    display: flex;
    align-items: center;
  }

  #sidebar-col > a{
    display: flex;
  }

  #sidebar-col > a > button{
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    padding-left: 0.6em;
    padding-right: 0.6em;
    margin: 0;
  }

  #navlinks{
    display: flex;
    font-size: base;
    padding-left: 0;
    margin: 0;
    justify-content: space-between;
    margin-left: 2%;
    
  }

  #navlinks li{
    margin: 0;
    margin-left: 2%;
    margin-right: 2%;
  }

  #content-col {
    max-width: initial;
  }

  .categories{
    display: none;
  }

  #categories-header {
    display: none
  }

  .post-header {
    font-size: 1.5em;
  }

  .twitter-btn {
    margin-top: 5%;
    text-align: center;
    margin-bottom: 5%;
  }

  .article-img-large{
    width: 85vw;
  }

  .article-img-small{
    width: 65vw;
  }

  .hide-mobile{
    display: none;
  }
  .content-content {
    font-weight: 400;
    line-height: 1.6em;
    margin-top: 2%;
    margin-bottom: 2%;
    font-size: medium;
  }
  h4{
    font-size: medium;
  }

  .book-img{
    width: 33%;
  }
}

/* Tablet Portrait */
@media only screen and (min-width: 600px) {
  body {
    padding-top: 3%;
  }

  #main-section {
    padding-left: 3%;
    background-color: white;
  }

  #sidebar-col {
    min-width: 150px;
  }

  #content-col {
    padding-left: 5%;
    padding-right: 5%;
    max-width: 600px;
  }
}

/* Tablet Landscape */
@media only screen and (min-width: 900px) {
  body {
    padding-top: 5%;
  }

  .categories > li {
    margin-top: 10%;
    font-size: small;
    opacity: 0.75;
  }

  #main-section {
    padding-left: 5%;
    background-color: white;
  }

  #sidebar-col {
    min-width: 150px;
  }

  #content-col {
    padding-left: 8%;
    padding-right: 5%;
    max-width: initial;
  }
}

/* Monitors */
@media only screen and (min-width: 1200px) {
  body {
    padding-top: 4%;
  }

  .categories > li {
    margin-top: 5%;
    opacity: 0.75;
  }

  #main-section {
    padding-left: 10%;
    background-color: white;
  }

  #sidebar-col {
    min-width: initial;
  }

  #content-col {
    padding-left: 8%;
    padding-right: 12%;
    max-width: initial;
  }
}

/* Monitors (Wide) */
@media only screen and (min-width: 1800px) {
  body {
    padding-top: 3%;
  }
  .categories > li {
    margin-top: 10%;
    font-size: small;
    opacity: 0.75;
  }

  #main-section {
    padding-left: 19%;
    background-color: white;
  }

  #sidebar-col {
    min-width: initial;
  }

  #content-col {
    padding-left: 5%;
    padding-right: 25%;
    max-width: initial;
  }

}

/* youtube embeds */
iframe{
  --container-width: 720px;
  --ratio: calc(16 / 9); /* 1.77 */
  width: 100%;
  height: calc(var(--container-width) / var(--ratio));
}

img {
  width: 100%;
  height: auto;
}
